import * as axios from 'axios';
export const AUTHORIZATION = 'Authorization';
export interface WsConfig {
  url: string
  prefix?: boolean
}

export class WS {
  private instance: axios.AxiosInstance;
  private prefix?: string;
  public config: WsConfig;

  constructor(config: WsConfig) {
    this.config = config;
    this.prefix = config.prefix == undefined ? '' : `${config.prefix} `;
    this.instance = axios.default.create({ baseURL: config.url });
  }

  public request(uuid: string, config: axios.AxiosRequestConfig): axios.AxiosPromise {
    if (config.headers == undefined)
      config.headers = {};

    // config.headers['x-request-id'] = uuid;
    return this.instance.request(config)
      .then((resp: axios.AxiosResponse) => {
        return resp;
      })
      .catch((err: any) => { throw err; });
  }

  public get(uuid: string, url: string, config: axios.AxiosRequestConfig = {}): axios.AxiosPromise {
    config.url = url;
    config.method = 'GET';

    return this.request(uuid, config);
  }

  public post(uuid: string, url: string, config: axios.AxiosRequestConfig): axios.AxiosPromise {
    config.url = url;
    config.method = 'POST';

    return this.request(uuid, config);
  }

  public put(uuid: string, url: string, config: axios.AxiosRequestConfig): axios.AxiosPromise {
    config.url = url;
    config.method = 'PUT';

    return this.request(uuid, config);
  }

  public patch(uuid: string, url: string, config: axios.AxiosRequestConfig): axios.AxiosPromise {
    config.url = url;
    config.method = 'PATCH';

    return this.request(uuid, config);
  }

  public delete(uuid: string, url: string, config: axios.AxiosRequestConfig): axios.AxiosPromise {
    config.url = url;
    config.method = 'DELETE';

    return this.request(uuid, config);
  }
}
