export const getColorSchemeProps: (
  colorScheme?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => Record<string, any> = (colorScheme) => {
  switch (colorScheme) {
    case "blue":
      return {
        bg: "blue.600",
        color: "white",
      };
    case "blue-light":
      return {
        bg: "blue.300",
        color: "white",
      };
    case "blue-lighter":
      return {
        bg: "blue.200",
        color: "blue.400",
      };
    case "white":
      return {
        bg: "white",
        color: "blue.400",
      };
    case "green":
      return {
        bg: "green.100",
        color: "green.500",
      };
    case "grey":
      return {
        bg: "grey.200",
        color: "grey.500",
      };
    default:
      return {
        bg: "blue.600",
        color: "white",
      };
  }
};
