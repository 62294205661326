import React, { lazy, Suspense } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from "./PrivateRoute";

const Dashboard = lazy(() => import('../pages/Dashboard'));
const SignInPage = lazy(() => import("../pages/Auth/SignIn"));
const SignUpPage = lazy(() => import("../pages/Auth/SignUp"));
const ConfirmEmailPage = lazy(() => import("../pages/Auth/ConfirmEmail"));

const AccountPage = lazy(() => import("../pages/Account/index"));
const ChangePasswordPage = lazy(() => import("../pages/Auth/ChangePassword"));
const ResetPasswordPage = lazy(() => import("../pages/Auth/ResetPassword"));

const Router = () => (
  <Suspense fallback={<Center h="100vh"><Spinner color="blue.500" /></Center>}>

    <Routes>
      <Route path="signin"  element={<SignInPage />} />
      <Route path="signup"  element={<SignUpPage />} />
      <Route path="confirm-email" element={<ConfirmEmailPage />} />

      <Route path="reset-password" element={<ResetPasswordPage />} />
      <Route path="change-password" element={<ChangePasswordPage />}/>

      <PrivateRoute path="/" element={<Dashboard />} />
      
      <PrivateRoute path="/account" element={<AccountPage />} />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  </Suspense>
);

export default Router;
