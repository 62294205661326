import { getCurrentToken } from './auth';
import { WS } from './ws'

export const getMeFromApi = async () => {
  const token = await getCurrentToken();
  if (!token) throw new Error();
  const config = {url: process.env.REACT_APP_API_URL || 'http://localhost:8002'};
  const apiCall = await new WS(config)
  const resp = await apiCall.get('xxxxxxxx', `/users/me`, { headers: { Authorization: `Bearer ${token}` }});
  return resp.data
};