import { extendTheme } from '@chakra-ui/react'
import { getColorSchemeProps } from "./utils";
export const colors = {
  blue: {
    800: '#10244d',
    600: '#5A52FF',
    500: '#22279D',
    300: '#6695FF',
    200: '#EDF3FF',
    100: '#E7E7FF',
  },
  grey: {
    800: '#212431',
    700: '#262a3e',
    650: '#464c63',
    600: '#6a6f85',
    500: '#81859a',
    400: '#dcdee6',
    300: '#e9eaf0',
    250: '#f0f1f5',
    200: '#F4F5FF',
    100: '#EDF2F7',
    50: '#9EA2C4',
  },
  lemon: {
    200: '#FFb900',
    100: '#FFF2C9',
  },
}

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        color: 'grey.800',
      },
      '*': {
        _focus: {
          outlineColor: 'grey.400',
        },
      },
    },
  },
  colors,
  shadows: {
    sm: '4px 4px 4px rgba(0, 0, 0, 0.1)',
    md: '8px 8px 8px rgba(0, 0, 0, 0.1)',
    lg: '12px 12px 12px rgba(0, 0, 0, 0.1)',
  },
  fonts: {
    body: "'Work Sans', sans-serif",
    heading: "'Work Sans', sans-serif",
    mono: "'Work Sans', sans-serif", // Caveat, cursive
  },
  fontSizes: {
    '2xs': '10px',
  },
  components: {
    FormLabel: {
      baseStyle: {
        fontSize: 'sm',
        color: 'grey.500',
        ml: 0,
      },
    },
    FormError: {
      baseStyle: {
        fontSize: 'sm',
        ml: 5,
      },
    },
    Tabs: {
      variants: {
        line: {
          tab: {
            color: 'grey.500',
            borderBottomWidth: '3px',
            _selected: {
              color: 'blue.900',
              borderColor: 'blue.500',
              borderBottomWidth: '3px',
            },
          },
        },
      },
    },
    Select: {
      defaultProps: {
        size: 'lg',
      },
      variants: {
        filled: {
          field: {
            _invalid: {
              bg: 'red.200',
              _placeholder: {
                color: 'red.500',
              },
            },
            _focus: {
              bg: 'blue.200',
              borderColor: 'blue.300',
              _placeholder: {
                color: 'blue.500',
              },
            },
          },
        },
      },
    },

    Textarea: {
      variants: {
        filled: {
          _invalid: {
            bg: 'red.200',
            _placeholder: {
              color: 'red.500',
            },
          },
          _focus: {
            bg: 'blue.200',
            borderColor: 'blue.300',
            _placeholder: {
              color: 'blue.300',
            },
          },
        },
      },
    },

    Input: {
      defaultProps: {
        size: 'lg',
        border: '1px',
      },
      variants: {
        unstyled: {
          field: {
            padding: '4',
            border: '1px',
            fontSize: 'sm',
            borderColor: 'grey.400',
            color: 'grey.500',
            bg: 'white',
            _invalid: {
              _placeholder: {
                color: 'red.300',
              },
            },
            _focus: {
              borderColor: 'grey.500',
            },
            _readOnly: {
              bg: 'grey.100',
              borderColor: 'grey.100',
            },
          },
        },
      },
    },

    Button: {
      baseStyle: {
        fontWeight: 400,
        width: '100%',
        rounded: 'lg',
      },
      sizes: {
        sm: {
          fontSize: 'sm',
        },
        md: {
          fontSize: 'sm',
          py: 7,
          px: 8,
        },
      },
      variants: {
        link: {
          textTransform: 'inherit',
          color: 'blue.600',
        },
        solid: ({ colorScheme }) => ({
          bg: getColorSchemeProps(colorScheme).bg,
          color: getColorSchemeProps(colorScheme).color,
          _disabled: {
            opacity: 1,
            bg: "gray.200",
            color: "gray.500",
          },
          _hover: {
            bg: getColorSchemeProps(colorScheme).bg,
            boxShadow: "md",
            _disabled: {
              bg: "gray.200",
              color: "gray.500",
            },
          },
        }),
      },
    },
    
    Alert: {
      baseStyle: {
        fontSize: 'xs',
      },
    },
  },
})

export default theme
