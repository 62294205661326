import { useQuery } from 'react-query'
import { getMeFromApi } from 'libs/api'
import { IUserProfile } from "../../types/api/user";

export const useMe = (options?: any) => {
  const { data, status, ...rest } = useQuery<IUserProfile, 'me'>('me', getMeFromApi, {
    retry: false,
    ...options,
  })
  const loading = status === 'loading'
  return { me: data, loading, status, ...rest }
}
