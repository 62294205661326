import Auth from '@aws-amplify/auth'
// import { load } from 'recaptcha-v3'

export const initialize = () => {
  Auth.configure({
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      scope: ["users/read", "users/write", "users/update", "users/delete"," auth/read", "auth/write", "orgs/read", "orgs/write", "orgs/update", "orgs/delete"],
    },
  })
}

export const HOME_URL = window.location.protocol + '//' + window.location.host

const CLIENT_METADATA = {
  useCommon: 'true',
  useSso: 'false',
  HOME_URL,
  logoUrl: `${process.env.APP_LOGO_URL} || ''`,
  clientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '',
  companyName: 'Labs',
}

export const requestNewVerificationToken = (email: string) => Auth.resendSignUp(email)

type SignProps = {
  email: string
  password: string
  metaData?: any
  connect?: () => Promise<{ success?: boolean } | undefined>
}
export const signIn = async ({ email, password, metaData, connect }: SignProps) => {
  // sign the user with cognito
  await Auth.signIn({ username: email, password }, undefined)
  const session = await Auth.currentSession()
  const user = session.getIdToken().decodePayload()
  console.log(user)
  if (user.isConfirmed === 'true') {
    return {}
  } else {
    // the present token does not have scope access to our app. The API will
    // reject queries made with it. We need to register the user in our API
    // before logging it in

    console.log('the present user cannot access. We will request access.')

    const result = await connect?.()

    if (!result?.success) throw new Error('cannot connect to present API')

    // Our API has updated the scope of the user. It needs to sign in again
    // to see those claims updated.

    console.log('Refreshing token claims')
    await Auth.signOut()
    await Auth.signIn({ username: email, password })
    return { user: null }
  }
}

export const signUp = async ({ email, password }: SignProps) => {
  try {
    // const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY || '')
    // recaptcha.showBadge()
    // const recaptchaToken = await recaptcha.execute('singUp')
    const result = await Auth.signUp({
      username: email,
      password,
      clientMetadata: {
        ...CLIENT_METADATA,
        actionUrl: `${HOME_URL}/signup/confirm`,
      },
    })
    // recaptcha.hideBadge()
    return result
  } catch (err) {
    throw err
  }
}

export const getMe = async () => {
  let user = await Auth.currentUserInfo()
  return {
    email: user.attributes.email,
    uuid: user.username,
    email_verified: user.attributes.email_verified,
  }
}

export const signOut = () => {
  return Auth.signOut()
}

export const resendCode = async (username: string) => {
  return Auth.resendSignUp(username, {
    ...CLIENT_METADATA,
    actionUrl: `${HOME_URL}/confirm-email`,
  })
}

export const confirmEmail = (email: string, code: string) => {
  return Auth.confirmSignUp(email, code)
}

export const getCurrentToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

export const resetPassword = async (email: string) => {
  const homeUrl = window.location.protocol + '//' + window.location.host
  return Auth.forgotPassword(email, {
    ...CLIENT_METADATA,
    actionUrl: `${homeUrl}/change-password`,
  })
}

export const changePassword = async (email: string, code: string, password: string) =>
  Auth.forgotPasswordSubmit(email, code, password)
