import { Center, Spinner } from "@chakra-ui/react";
import { useMe } from "../../hooks/auth/useMe";
import { ComponentPropsWithoutRef, FC } from "react";
import { Navigate, Route } from "react-router-dom";

export const PrivateRoute: FC<
  ComponentPropsWithoutRef<typeof Route> & { redirectTo?: string}
> = ({ redirectTo = "/signin", ...props }) => {
  // Getting me from API will need to fetch token first - or fail
  const { me, isLoading, error } = useMe();

  if (isLoading) {
    return (
      <Center h="100vh">
        <Spinner color="blue.600" />
      </Center>
    );
  }

  if (error) {
    return <Navigate to={redirectTo} />;
  }

  if (!me) {
    return (
      <Center h="100vh">
        <Spinner color="blue.600" />
      </Center>
    );
  }

  return (
    <Route {...props} />
  );
};
